<template>
  <div class="shopActivity-container">
    <el-row type="flex" justify="end">
				<el-button @click="onAdd" icon="el-icon-plus" type="primary">新建商户
				</el-button>
    </el-row>
    <el-form inline :model="searchForm" label-width="140px" style="margin-top: 30px;">
      <!-- <el-form-item label="入驻日期">
								<el-date-picker
										v-model="searchForm.dateArray"
										type="daterange"
										range-separator="至"
										value-format="yyyy-M-d"
										start-placeholder="年/月/日"
										end-placeholder="年/月/日">
								</el-date-picker>
						</el-form-item> -->

            <!-- <el-form-item label="商户类型">
								<el-select v-model="searchForm.typeId" placeholder="请选择">
									 <el-option v-for="item in typeList" :key="item.dictId" :label="item.dictName"
															 :value="item.dictId"></el-option>
								</el-select>
						</el-form-item>
            <el-form-item label="商户等级">
								<el-select v-model="searchForm.gradeId" placeholder="请选择">
										<el-option v-for="item in gradeList" :key="item.dictId" :label="item.dictName"
															 :value="item.dictId"></el-option>
								</el-select>
						</el-form-item>
						<el-form-item label="商户状态">
								<el-select v-model="searchForm.statusId" placeholder="请选择">
										<el-option v-for="item in statusList" :key="item.statusId" :label="item.statusName"
															 :value="item.statusId"></el-option>
								</el-select>
						</el-form-item> -->

      <el-form-item label="关键字">
        <el-input v-model="searchForm.key" autocomplete="off"></el-input>
      </el-form-item>
      <el-button @click="onSearch" type="primary" icon="el-icon-search">搜索</el-button>
    </el-form>

 
    <common-table :data="companyList" style="width: 100%;" :size-pre-page="10">
      <el-table-column prop="companyCode" align="center" label="公司编码"  ></el-table-column>
      <el-table-column prop="companyName" align="center" label="公司名称"  ></el-table-column>
      <el-table-column prop="fromtoDate" align="center" label="有效日期"  ></el-table-column>
      <el-table-column prop="contactUser" align="center" label="联系人" width="200"></el-table-column>
      <el-table-column prop="address" align="center" label="联系地址"  ></el-table-column>
      <el-table-column prop="tel" align="center" label="联系电话"  width="200"></el-table-column>
      <el-table-column prop="uName" align="center" label="创建人" width="100"></el-table-column>
      <el-table-column prop="rowTime" align="center" label="创建时间" width="200"></el-table-column>

      <el-table-column prop="target" align="center" label="操作" width="170">
        <template v-slot="scope">
          <el-button type="primary" size="small"
            @click.native.prevent="handleEdit(scope.row)" icon="el-icon-edit"
          ></el-button>

          <el-button type="danger" size="small" @click.native.prevent="handleDelete(scope.row)" 
             icon="el-icon-delete"
          ></el-button>
        </template>
      </el-table-column> 
    </common-table>

    <!-- <product-edit-dialog :visible.sync="createDialogVisible"></product-edit-dialog> -->
    <!-- <order-edit-dialog :visible.sync="editOrderDialogVisible" :form-extra="currentItem"></order-edit-dialog>
    <deliver-goods :visible.sync="editDeliverVisible" :form-extra="currentItem"></deliver-goods> -->
    <!-- <edit-price :visible.sync="editPriceVisible" :form-extra="currentItem"></edit-price> -->
    <el-dialog title="编辑商户" :close-on-click-modal="false" :visible.sync="editDialogFormVisible">
        <el-form :inline="true" :model="editForm">
                <el-form-item label="商户编码" label-width="100px">
                    <el-input v-model="editForm.companyCode" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="商户名称" label-width="100px">
                    <el-input v-model="editForm.companyName" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="商户类型" label-width="100px">
                    <el-select v-model="editForm.typeId" placeholder="请选择">
                        <el-option
                                v-for="item in typeList"
                                :key="item.dictId"
                                :label="item.dictName"
                                :value="item.dictId">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="商户等级" label-width="100px">
                    <el-select v-model="editForm.gradeId" placeholder="请选择">
                        <el-option
                                v-for="item in gradeList"
                                :key="item.dictId"
                                :label="item.dictName"
                                :value="item.dictId">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="开户行" label-width="100px">
                    <el-input v-model="editForm.bank" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="账号" label-width="100px">
                    <el-input v-model="editForm.account" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="注册资金" label-width="100px">
                    <el-input v-model="editForm.regAmount" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="注册日期" label-width="100px">
                    <el-input v-model="editForm.regDate" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="联系地址" label-width="100px">
                    <el-input v-model="editForm.address" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="联系人" label-width="100px">
                    <el-input v-model="editForm.contactUser" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="联系电话" label-width="100px">
                    <el-input v-model="editForm.tel" autocomplete="off"></el-input>
                </el-form-item>
                <el-row>
                  <my-upload-file-form-item v-model="editForm.logoArray" :limit="1" subDir='Logo/' label="企业LOGO"/>
						    </el-row>
                <!-- <el-form-item label="状态">
                  <el-col :span="24">
                      <el-select v-model="editForm.statusId" placeholder="请选择">
                          <el-option
                              v-for="item in statusList"
                              :key="item.statusId"
                              :label="item.statusName"
                              :value="item.statusId">
                          </el-option>
                      </el-select>
                  </el-col>
              </el-form-item> -->
              
              <!-- <div class="tableTitle"><span class="midText">模块权限</span></div>

              <el-table :data="roleModuleList" border
											ref="permissionTable"
											max-height="350"
											:header-cell-style="{background:'#eef1f6',color:'#606266'}"
											@selection-change="handleSelectionChange">
                  <el-table-column
                      type="selection"
                      align="center"
                      width="55">
                  </el-table-column>
                  <el-table-column label="功能模块" prop="label" align="center" width="250"></el-table-column>
                  <el-table-column label="权限" prop="label" align="center">
                      <template slot-scope="scope">
                          <div class="flex-row justify-content-center fill-width">
                              <el-checkbox-group v-model="scope.row.permissionsCheckArray"
                                                @change="handleCheckedCitiesChange($event,scope.row)">
                                  <el-checkbox v-for="permission in permissions" :label="permission" :key="permission">
                                      {{permission}}
                                  </el-checkbox>
                              </el-checkbox-group>
                              <el-checkbox :indeterminate="scope.row.isIndeterminate" v-model="scope.row.checkAll"
                                          @change="handleCheckAllChange($event,scope.row);" style="margin-left: 30px;">全选
                              </el-checkbox>
                          </div>
                      </template>

                  </el-table-column>
              </el-table>
               -->
                
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="editDialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="onConfirm">确 定
                </el-button>
            </div>
    </el-dialog>

  </div>
</template>

<script>
import CommonTable from "@/components/CommonTable";
import CommonTableColumn from "@/components/CommonTableColumn";
import {getLatestMonthDateArrayByDate} from "@/utils/time";
import { getTypeList,getStatusList } from "@/api/support";
import { mapGetters } from "vuex";
import { getToken } from "@/utils/auth";
import {AdminCompanyDelete,} from "@/api/user";
import MyUploadFileFormItem from "@/components/MyUploadFileFormItem";
// import {getFileUrl} from "@/api/file";


export default {
  name: "Advertiser",
  // components: {LookMakePlanDialog, AdvertiserEditDialog, CommonTable},
  components: { CommonTable,  MyUploadFileFormItem,CommonTableColumn}, //,ProductEditDialog
  computed: { ...mapGetters(["companyList",'roleModuleList']) },
  data() {
    return {
 
      editDialogFormVisible:false,
      typeList:[],
      gradeList:[],
      statusList:[],
      searchForm: {
        key: "",
        dateArray: ['2021-01-01','2099-12-31'],//getLatestMonthDateArrayByDate({date:new Date(),delayNum:1, formatValue: 'yyyy-MM-dd'}),
      },
      editForm:{
        companyId:0,
        companyName:'',
        companyCode:'',
        logo:'',
        logoArray:[],
        typeId:0,
        gradeId:0,
        account:'',
        bank:'',
        regAmount:0,
        regDate:'',
        address:'',
        contactUser:'',
        tel:'',
      },
      currentItem: {},
    };
  },
  async created() {
    // const tData = await getTypeList({ userId:this.$store.state.user.userId, dictType: '商户类型' })
    //   ,gData = await getTypeList({ userId:this.$store.state.user.userId, dictType: '商户级别' })
    //   ,sData = await getStatusList({ userId:this.$store.state.user.userId, statusType: '审批' });
    // this.typeList = tData.data;
    // this.gradeList = gData.data;
    // this.statusList = sData.data;
    // this.typeList.splice(0,1);  
    // this.gradeList.splice(0,1);  
    // await this.$store.dispatch("operate/couponList", {
        
    //   });
    await this.onSearch();
  },

  // async beforeCreate() {
	// 			this.$store.dispatch('products/statusTypeList');
  //   },
    
  methods: {

    async handleDelete(target) {
               
                await this.$confirm('是否确定删除该商户', '删除商户', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                });
                try {
                    await AdminCompanyDelete({companyId: target.companyId, userId: this.$store.state.user.userId});
                } catch (e) {
                    this.$message({type: "error", message: '删除失败，请重试'});
                    console.error(e);
                }
                this.$message({type: "success", message: '删除成功'});
								await this.onSearch();
		},

    onAdd() {
        this.handleEdit({});
    },
    async handleEdit(item) {
             
        this.editForm = {...item};
        // console.log('ddd',this.editForm.logo)
        // if (Object.keys(this.editForm.logo).length>0){
          // console.log('jinlai ')
          const {logo,fullLogo} = this.editForm;
          this.editForm.logoArray = this.getArrayFromSever({logo,fullLogo});
        // }else{
        //   console.log('sasalsa???')
        // }
        
        this.editDialogFormVisible = true;   
    },

    async onSearch() {
      console.log(this.searchForm);
      const [sDate, eDate] = this.searchForm.dateArray;
      // console.log(sDate,eDate,this.searchForm.statusId);
      await this.$store.dispatch("company/AdminCompanyList", {
        sDate:sDate,
        eDate:eDate,        
        typeId:this.searchForm.typeId,        
        gradeId:this.searchForm.gradeId,
        statusId:this.searchForm.statusId,
        key: this.searchForm.key,
      });
    },

    async onConfirm() {
				
					const {
							companyName,typeId, gradeId,account,bank,regAmount,regDate,address,contactUser,tel
						} = this.editForm;
					if (!companyName?.trim()) {
						this.$message.warning('请输入商户名称')
						return;
          }
          if (!typeId) {
              this.$message.warning('请选择商户类型');
              return;
          }
          if (!gradeId) {
              this.$message.warning('请选择商户等级');
              return;
          }

          if (!this.editForm.companyId) {
              this.editForm.companyId='';
          }
					// if (!cateIds?.trim()) {
					// 	this.$message.warning('请选择产品分类')
					// 	return;
					// }
					// if (!price) {
					// 	this.$message.warning('请输入价格')
					// 	return;
      		// 		}
          this.editForm.logo=this.editForm.logoArray?.map(item => item.name).join(';') ?? '';
          
					await this.$store.dispatch('user/AdminCompanyModify', {...this.editForm});
          this.$message.success('编辑成功');
          this.editDialogFormVisible = false;
					await this.onSearch();
						// this.$router.go(-1);

    },
       
    getArrayFromSever({logo,fullLogo}) {
      // console.log('logo',logo);
      return logo?.split(';').filter(item=>!!item).map(fileName => {
          // return {name: fileName, url: getFileUrl({name: fileName})};
          return {name: fileName, url: fullLogo};
      }) ?? []
    },

  },
};
</script>

<style scoped>
.tableTitle {
    position: relative;
    margin: 30px auto;
    width: 90%;
    height: 2px;
    background-color: #d4d4d4;
    text-align: center;
    font-size: 16px;
    color: rgba(101, 101, 101, 1);
  }
 .midText {
    position: absolute;
    left: 50%;
    background-color: #ffffff;
    padding: 0 15px;
    transform: translateX(-50%) translateY(-50%);
  }
</style>